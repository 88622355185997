import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import {
  Sidebar, Map, RedirectModal, Loader,
} from '@/components';

import { isNullOrWhiteSpace } from '@/utils';
import _ from 'lodash';
import isServer from '@/utils/isServer';

if (!isServer) {
  const { extend } = require('vee-validate');
  const {
    required, email, length, confirmed,
  } = require('vee-validate/dist/rules');
  // const moment = require('moment');
  extend('required', {
    ...required,
    message: 'Dit veld is vereist.',
  });

  extend('length', {
    ...length,
    message: 'Dit is geen correct licentienummer.',
  });

  extend('email', {
    ...email,
    message: 'Een geldig e-mailadres is vereist.',
  });

  extend('confirmed', {
    ...confirmed,
    message: 'De e-mailadressen komen niet overeen.',
  });

  extend('licenseNumber', {
    validate: (licenseNumber) => {
      var firstNumbers = parseInt(licenseNumber.substr(0, 4), 10);
      var nextYear = new Date().getFullYear() + 1;
      if (firstNumbers > 1900 && firstNumbers <= nextYear) {
        return true;
      }
      return false;
    },
    message: 'Dit is geen correct licentienummer.',
  });

  extend('viable-date', {
    validate: (date) => {
      var dateParts = date.split('/');
      var day = dateParts[0];
      var month = dateParts[1] - 1;
      var year = dateParts[2];
      var inputDate = new Date(year, month, day);
      var dateIsInvalid = Date.parse(inputDate)
        .toString()
        .toLowerCase() === 'nan';
      if (dateIsInvalid) return false;
      return true;
    },
    message: 'Gelieve een geldige datum in te voeren.',
  });

  extend('max-date', {
    validate: (date) => {
      var dateParts = date.split('/');
      var day = dateParts[0];
      var month = dateParts[1] - 1;
      var year = dateParts[2];
      var inputDate = new Date(year, month, day);
      var lastPossibleDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
      if (inputDate > lastPossibleDate) {
        return false;
      }
      return true;
    },
    message: 'Je moet minstens 1 jaar oud zijn om deel te nemen aan een activiteit.',
  });

  extend('min-age', {
    validate: (date, { minAge }) => {
      var dateParts = date.split('/');
      var day = dateParts[0];
      var month = dateParts[1] - 1;
      var year = dateParts[2];
      var inputDate = new Date(year, month, day);
      if (moment().diff(moment(inputDate), 'years') < minAge) {
        return false;
      }
      return true;
    },
    params: ['minAge'],
    message: 'Je moet minstens {minAge} jaar oud zijn om deel te nemen aan deze activiteit.',
  });

  extend('max-age', {
    validate: (date, { maxAge }) => {
      var dateParts = date.split('/');
      var day = dateParts[0];
      var month = dateParts[1] - 1;
      var year = dateParts[2];
      var inputDate = new Date(year, month, day);
      if (moment().diff(moment(inputDate), 'years') > maxAge) {
        return false;
      }
      return true;
    },
    params: ['maxAge'],
    message: 'Je mag maximum {maxAge} jaar oud zijn om deel te nemen aan deze activiteit.',
  });
}

export default {
  metaInfo: {
    title: 'Event registerpage',
  },
  name: 'EventRegister',
  directives: {
    mask,
  },
  components: {
    [Sidebar.name]: Sidebar,
    [RedirectModal.name]: RedirectModal,
    [Map.name]: Map,
    [Loader.name]: Loader,
  },
  props: {},
  data: () => ({
    showRedirectModal: false,
    redirectModalIsActive: false,
    redirectTitle: '',
    linkText: '',
    link: '',
    validLicenseNumbers: 0,
    participants: [],
    mapLoaded: false,
    questions: [],
    map: {
      zoom: 14,
      events: [],
      center: [3.0, 51.0],
      showEvents: true,
    },
    urlConditions: process.env.VUE_APP_URL_CONDITIONS,
    urlPolicy: process.env.VUE_APP_URL_POLICY,
    genderArray: [
      { name: 'Man', value: 'M' },
      { name: 'Vrouw', value: 'V' },
      { name: 'Andere', value: 'X' },
    ],
    countryArray: [
      { name: 'België', value: 150 },
      { name: 'Nederland', value: 129 },
      { name: 'Frankrijk', value: 111 },
      { name: 'Luxemburg', value: 113 },
      { name: 'Duitsland', value: 103 },
      { name: 'Spanje', value: 109 },
    ],
  }),
  computed: {
    ...mapGetters('event', {
      event: 'getEvent',
      orderPrice: 'getOrderPrice',
      registerParticipantsLoading: 'getRegisterParticipantsLoadingState',
    }),
    ...mapGetters('account', {
      currentUser: 'getCurrentUser',
    }),
    website() {
      if (this.event.club.website.includes('http')) return this.event.club.website;
      return `//${this.event.club.website}`;
    },
  },
  methods: {
    ...mapActions('event', {
      initialiseEvent: 'FETCH_DATA_EVENT',
      submitParticipantsForm: 'REGISTER_PARTICIPANTS',
      fetchOrderPrice: 'FETCH_DATA_EVENT_ORDER_PRICE',
    }),
    addParticipant() {
      const emptyParticipant = {
        eventId: this.event.id,
        collapsed: false,
        firstname: '',
        lastname: '',
        sex: '',
        email: '',
        repeatEmail: '',
        address_Street: '',
        address_Nr: '',
        address_Postcode: '',
        address_City: '',
        phone: '',
        birthDay: null,
        consentToBeVisible: false,
        selectedGender: null,
        genderErrorMessage: null,
        licenseNumber: null,
        answers: {},
        mailChimpOptIn: false,
        showExtraSportkampFields: false,
        campRegistrationInfo: null,
      };

      this.questions.forEach((question) => {
        emptyParticipant.answers[question.id] = { answer: null };
      });

      // Bij toevoegen van een nieuwe gebruiker de anderen dichtklappen.
      this.participants.forEach((participant) => {
        // eslint-disable-next-line no-param-reassign
        participant.collapsed = true;
      });
      this.participants.push(emptyParticipant);
      this.fetchPrice();
    },
    autoFill(index) {
      if (this.$isLoggedIn) {
        var mindate = '0001-01-01T00:00:00Z';
        if (this.currentUser) {
          if (!this.currentUser.addressStreet && !this.currentUser.addressCity) {
            this.linkText = 'Ga naar profiel';
            this.link = 'profile-edit';
            this.redirectTitle = 'Momenteel heb je je profiel niet verder aangevuld, ga naar de profiel pagina om jouw gegevens aan te vullen';
            this.showRedirectModalFunction();
          } else {
            this.participants[index].firstname = this.currentUser.firstname;
            this.participants[index].lastname = this.currentUser.lastname;
            this.participants[index].selectedGender = this.genderArray.find(
              (g) => g.value === this.currentUser.sex,
            );
            this.participants[index].email = this.currentUser.email;
            this.participants[index].address_Street = this.currentUser.addressStreet;
            this.participants[index].address_City = this.currentUser.addressCity;
            this.participants[index].address_Postcode = this.currentUser.addressPostalcode;
            this.participants[index].address_Nr = this.currentUser.addressNumber;
            if (this.currentUser.birthDate !== mindate) {
              this.participants[index].birthDay = this.$options.filters.formatDate(
                new Date(this.currentUser.birthDate),
              );
            }
            this.participants[index].phone = this.currentUser.phone;
            this.participants[index].licenseNumber = this.currentUser.licenseNumber;
          }
        }
        this.updateGender(
          this.genderArray.find((g) => g.value === this.currentUser.sex),
          index.toString(),
        );
      } else {
        this.linkText = 'Log in';
        this.link = 'login';
        this.redirectTitle = 'Momenteel ben je niet aangemeld, ga naar de registratie pagina om een profiel aan te maken';
        this.showRedirectModalFunction();
      }
    },
    calculateIfExtraSportkampFieldsShouldBeShown(index) {
      const birthDate = moment(this.participants[index].birthDay, 'DD/MM/YYYY').toDate(); // Parse the date string correctly
      const age = moment().diff(moment(birthDate), 'years');
      const showExtraSportkampFields = (age < 14) && this.event.eventIsSportkamp;
      this.participants[index].showExtraSportkampFields = showExtraSportkampFields;

      if (showExtraSportkampFields) {
        this.participants[index].campRegistrationInfo = {
          parentFirstname: '',
          parentLastname: '',
          parentAddress_Street: '',
          parentAddress_Nr: '',
          parentAddress_Postcode: '',
          parentAddress_City: '',
          parentNationalRegisterNumber: '',
          parentCountry: 0,
          selectedParentCountry: {},
          parentCountryErrorMessage: '',
          childNationalRegisterNumber: '',
          childCountry: 0,
          selectedChildCountry: [],
          childCountryErrorMessage: '',
        };
      } else {
        this.participants[index].campRegistrationInfo = null;
      }
    },
    removeParticipant(index) {
      this.participants.splice(index, 1);
      this.fetchPrice();
    },
    submitRegister() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          setTimeout(() => {
            const errorKeys = Object.keys(this.$refs.form.errors);
            const errors = errorKeys.filter((e) => this.$refs.form.errors[e].length > 0);

            this.$refs.form.refs[errors[0]].$el.scrollIntoView();
            window.scrollBy(0, -75);
          }, 100);
        } else {
          // Check if all sex dropdowns have a value:
          const emptySexParticipants = this.participants.filter(
            (participant) => participant.sex === '',
          );
          emptySexParticipants.forEach((participant) => {
            const p = participant;
            p.genderErrorMessage = 'Gelieve een geslacht te selecteren.';
          });

          // Check if all parent countries are selected (only if particpant campRegistrationInfo is not null)
          const emptyParentCountryParticipants = this.participants.filter(
            (participant) => participant.campRegistrationInfo !== null
              && participant.campRegistrationInfo.parentCountry === 0,
          );

          emptyParentCountryParticipants.forEach((participant) => {
            const p = participant;
            p.campRegistrationInfo.parentCountryErrorMessage = 'Gelieve een land te selecteren.';
          });

          // Check if all child countries are selected (only if particpant campRegistrationInfo is not null)
          const emptyChildCountryParticipants = this.participants.filter(
            (participant) => participant.campRegistrationInfo !== null
              && participant.campRegistrationInfo.childCountry === 0,
          );

          emptyChildCountryParticipants.forEach((participant) => {
            const p = participant;
            p.campRegistrationInfo.childCountryErrorMessage = 'Gelieve een land te selecteren.';
          });

          const params = {
            id: this.event.id,
            participantsArray: _.cloneDeep(this.participants),
          };

          if (emptySexParticipants.length > 0 || emptyParentCountryParticipants.length > 0 || emptyChildCountryParticipants.length > 0) {
            return;
          }

          this.submitParticipantsForm(params)
            .then((response) => {
              if (response.status === 200) {
                window.location = response.data.redirectUrl;
              }
            })
            .catch((error) => {
              this.$toasted.show(...error.response.data);
            });
        }
      });
    },
    updateGender(selectedGender, id) {
      const index = id.charAt(0);
      // index == id from the multiselect
      // this is the only way to get the right participant in the list
      this.participants[index].selectedGender = selectedGender;
      this.participants[index].sex = selectedGender.value;
      this.participants[index].genderErrorMessage = null;
    },
    updateCountryParent(selectedCountry, id) {
      const index = id.charAt(0);

      this.participants[index].campRegistrationInfo.parentCountry = selectedCountry.value;
      this.participants[index].campRegistrationInfo.parentCountryErrorMessage = null;
    },
    updateCountryChild(selectedCountry, id) {
      const index = id.charAt(0);

      this.participants[index].campRegistrationInfo.childCountry = selectedCountry.value;
      this.participants[index].campRegistrationInfo.childCountryErrorMessage = null;
    },
    async fetchPrice() {
      const checkLicenseNumbers = async (array) => {
        let validLicenseNumbersCount = 0;

        if (this.$refs.licenseNumber === undefined) {
          return 0;
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const index of array.keys()) {
          // eslint-disable-next-line no-await-in-loop
          const check = await this.$refs.licenseNumber[index].validate();
          if (check.valid && !isNullOrWhiteSpace(this.$refs.licenseNumber[index].value)) {
            validLicenseNumbersCount += 1;
          }
        }

        return validLicenseNumbersCount;
      };

      var validLicenseNumberCount = await checkLicenseNumbers(this.participants);

      this.validLicenseNumbers = validLicenseNumberCount;

      const params = {
        eventId: this.event.id,
        numberOfParticipants: this.participants.length,
        validLicenseNumberCount,
      };

      this.fetchOrderPrice(params);
    },
    fillParticipants() {
      return new Promise((resolve) => {
        this.addParticipant();
        resolve();
      });
    },
    closeModal() {
      this.redirectModalIsActive = false;
      this.showRedirectModal = false;
    },
    showRedirectModalFunction() {
      this.redirectModalIsActive = true;
      this.showRedirectModal = true;
    },
  },
  created() {
    this.initialiseEvent(this.$route.params.eventurl).then(() => {
      if (this.event.questions !== undefined) {
        this.questions.length = this.event.questions.length;
        this.event.questions.forEach((question, index) => {
          this.questions[index] = {
            question: question.isRequired ? `${question.question} *` : question.question,
            isRequired: question.isRequired,
            id: question.id,
          };
        });
      }

      const coordinates = [this.event.lat, this.event.long];
      this.map.events = [];
      this.map.events.push(this.event);
      this.map.center = coordinates;

      this.mapLoaded = true;

      this.fillParticipants().then(() => {
        this.fetchPrice();
      });
    });
  },
};
